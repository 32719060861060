<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1" height="52">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-3 pb-1">
      <div class="conducted-box d-flex">
        <v-alert
            dense
            text
            class="mb-0 py-1"
            :color="conducted ? 'success' : 'secondary'"
            :type="conducted ? 'success' : 'warning'"
            style="flex: 1"
        >
          {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
        </v-alert>
      </div>
      <div class="document-header">
        <div class="document-header-row pt-2 pb-1">
          <div style="flex: 0 0 180px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true"></date_input>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"></string_input>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <date_input v-model="month" label="Місяць: " :time="false" :month_type="true" />
          </div>
        </div>
        <div class="document-header-row pt-2 pb-1">
          <v-switch label="Перенести платежі" v-model="close_pays" hide-details color="success"/>
        </div>
        <div class="document-header-row pt-2 pb-1">
          <v-switch label="Провести перенесені платежі одним документом (кінець місяця)"
                    v-model="close_pays_general" hide-details color="success"/>
        </div>
        <div class="document-header-row pt-2 pb-1">
          <v-switch label="Перенести нарахування" v-model="close_charge" hide-details color="success"/>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-3" v-if="!conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(true)">
        <v-icon color="success">mdi-file-check-outline</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(false)">
        <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
      </v-btn>
      <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Провести та закрити
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
  UPDATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
  REMOVE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
  CONDUCTED_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import closeHomeAccountingAPI from "@/utils/axios/accounting/documents/close_home_accounting";
import {format_date_from_python} from "@/utils/icons";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component_wto";

const modalDeleteId = 'close_home_accounting_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Document_CloseHomeAccounting",
  mixins: [ModalAccountingComponentMixin],
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
  },
  data() {
    return {
      month: this.item.month || null,
      close_pays: this.item.close_pays || false,
      close_pays_by_cash_order: this.item.close_pays_by_cash_order || false,
      close_pays_general: this.item.close_pays_general || false,
      close_charge: this.item.close_charge || false,
      close_charge_general: this.item.close_charge_general || false,
    }
  },
  methods: {
    checkDocument(show=false) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'month': {id: 'month', text: 'Не заповнений місяць'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, {text, color})
            }

          })
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.itemId = payload.id || null
      this.date = local_data.date || null
      this.month = local_data.month || null
      this.number = local_data.number || null
      this.conducted = local_data.conducted || false
      this.close_pays = local_data.close_pays || false
      this.close_pays_by_cash_order = local_data.close_pays_by_cash_order || false
      this.close_pays_general = local_data.close_pays_general || false
      this.close_charge = local_data.close_charge || false
      this.close_charge_general = local_data.close_charge_general || false
    },
    fetch_data_by_id() {
      if (this.isNew) return

      closeHomeAccountingAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.date = data.date || null
            this.month = data.month || null
            this.number = data.number || null
            this.conducted = data.conducted || false
            this.close_pays = data.close_pays || false
            this.close_pays_by_cash_order = data.close_pays_by_cash_order || false
            this.close_pays_general = data.close_pays_general || false
            this.close_charge = data.close_charge || false
            this.close_charge_general = data.close_charge_general || false
          })
    },

    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.date = this.item.date || null
      this.month = this.item.month || null
      this.number = this.item.number || null
      this.conducted = this.item.conducted || null
      this.close_pays = this.item.close_pays || false
      this.close_pays_by_cash_order = this.item.close_pays_by_cash_order || false
      this.close_pays_general = this.item.close_pays_general || false
      this.close_charge = this.item.close_charge || false
      this.close_charge_general = this.item.close_charge_general || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа закриття місяця (поквартиний облік) № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        month: this.month,
        close_pays: this.close_pays,
        close_pays_by_cash_order: this.close_pays_by_cash_order,
        close_pays_general: this.close_pays_general,
        close_charge: this.close_charge,
        close_charge_general: this.close_charge_general,
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.number = this.number
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.checkDocument()
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_CLOSE_HOME_ACCOUNTING_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    }
  }
}
</script>
